import React, { useState, useRef, useEffect,Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login,prelogin,loginSuperAdmin} from "../../actions/auth";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import { isEmail } from "validator";
import BoLoader from "../../helpers/BoLoader";
import useWindowDimensions from "../../helpers/WindowWidth";
import ReCAPTCHA from "react-google-recaptcha";
import TwoFaLogin from "../account/TwoFaLogin";
import ModelPopup from "../../helpers/ModelPopup";
const requiredEmail = (value) => {
  if (!value) {
    document.getElementById("EmailFrmgrp_lgn").classList.add("has_error");
    return (
      <span className="help-block">Please enterd a valid email address</span>
    );
  } else
    document.getElementById("EmailFrmgrp_lgn").classList.remove("has_error");
};
const requiredPass = (value) => {
  if (!value) {
    document.getElementById("PassFrmgrp_lgn").classList.add("has_error");
    return <span className="help-block">Please enterd a valid password</span>;
  } else
    document.getElementById("PassFrmgrp_lgn").classList.remove("has_error");
};
const validEmail = (value) => {
  if (!isEmail(value)) {
    document.getElementById("EmailFrmgrp_lgn").classList.add("has_error");
    return (
      <span className="help-block">Please enterd a valid email address</span>
    );
  } else
    document.getElementById("EmailFrmgrp_lgn").classList.remove("has_error");
};
const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { height, width } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [passwordShown, setPasswordShown] = useState(false);
  const [showHidePassClass, setshowHidePassClass] = useState("icon-show");
  const [rmbrMeChkBox, setrmbrMeChkBox] = useState(false);

  const [hasErrorClass, setconditionalClassName] = useState("");
  const [showTwoFA, setShowTwoFA] = useState(false);

  const showHidePassHandler = () => {
    setPasswordShown(passwordShown ? false : true);
    setshowHidePassClass(passwordShown ? "icon-show" : "icon-hidden");
  };
  const dispatch = useDispatch();
  const [confirmcaptcha, setConfirmcaptcha] = useState(false);

  const onChangeUsername = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const toggleRemmberMe = (e) => {
    const chkRememberMe = e.target.checked;
    if (chkRememberMe) {
      setRememberMe(true);
      setrmbrMeChkBox(true);
    } else {
      setRememberMe(false);
      setrmbrMeChkBox(false);
    }
  };
  useEffect(() => {
    let userEmail = localStorage.getItem("boUserEmail");
    let userPass = localStorage.getItem("boUserPassword");
    if (userEmail !== null && userPass !== null) {
      setEmail(userEmail);
      setPassword(userPass);
      setRememberMe(true);
      setrmbrMeChkBox(true);
    }
  }, []);
  const handleLogin = (e) => {
    if(email==="rehan@bopayments.com")
    {
      e.preventDefault();
      form.current.validateAll();
      if (!confirmcaptcha) return;
      if (checkBtn.current.context._errors.length === 0) {
       
        setLoading(true);
        dispatch(loginSuperAdmin(email, password, rememberMe))
          .then(() => {
            props.history.push("/dashboard");
            setLoading(false);
            //setShowTwoFA(true);
            setconditionalClassName("");
          })
          .catch((err) => {
            setLoading(false);
            setconditionalClassName("onbo-content-top");
          });
      } else {
        setLoading(false);
        setconditionalClassName("");
      }
      
    }
    else{
      
      if(e){
      e.preventDefault();
      form.current.validateAll();
      if (!confirmcaptcha) return;
      if (checkBtn.current.context._errors.length === 0) {
       
        setLoading(true);
        dispatch(prelogin(email, password, rememberMe))
          .then(() => {
            //props.history.push("/dashboard");
            setLoading(false);
            setShowTwoFA(true);
            setconditionalClassName("");
          })
          .catch((err) => {
            setLoading(false);
            setconditionalClassName("onbo-content-top");
          });
      } else {
        setLoading(false);
        setconditionalClassName("");
      }
    }
    else{
      dispatch(prelogin(email,password))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setconditionalClassName("onbo-content-top");
      });
    }
  }
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
  const confirmCaptcha = (success) => {
    if(success)
    setConfirmcaptcha(true);
  };
  const verifyCaptcha = (success) => {
    if(success)
    setConfirmcaptcha(true);
  };
  const expiryCaptcha = () => {
    setConfirmcaptcha(false);
  };
  return (
    <div className="row minvh-100">
      <BoPaymentWrapper pagename="Sign In" />

      <div className={"col-md-7 col-lg-8 onbo-content " + hasErrorClass}>
        <div className="row">
          <div className="bo-on-screens position-relative">
            {message && (
              <div className="form-group">
                <div className="col-sm-12 col-md-10 alert-messages alert-info alert-mbl">
                  <span className="icon-info"></span>

                  <p>{message}</p>
                </div>
              </div>
            )}
            <div className="col-md-8 col-lg-5">
              <BoLoader loading={loading} />
              <Form onSubmit={handleLogin} ref={form}>
                <div className="form-group" id="EmailFrmgrp_lgn">
                  <label htmlFor="email">Email</label>
                  <div className="form-controls">
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeUsername}
                      validations={[requiredEmail, validEmail]}
                      placeholder="Enter Email"
                    />
                    <span className="icon-email _ico"></span>
                  </div>
                </div>

                <div
                  className="form-group mb-20 password-field"
                  id="PassFrmgrp_lgn"
                >
                  <label htmlFor="password">Password</label>
                  <div className="form-controls">
                    <Input
                      id="show_hide_login"
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[requiredPass]}
                      placeholder="Enter Password"
                    />
                    <span className="icon-lock _ico"></span>
                    <span
                      className={"show-password " + showHidePassClass}
                      onClick={showHidePassHandler}
                    ></span>
                  </div>
                </div>

                <div className="form-group mb-40 row">
                  <div className="col-md-7 col-6 form-checkbox">
                    <input
                      type="checkbox"
                      name="remembermechk"
                      id="remembermechk"
                      onClick={toggleRemmberMe}
                      className="form-check-input"
                      checked={rmbrMeChkBox}
                    />
                    <label
                      className="checkbox-label clr-submarine"
                      htmlFor="remembermechk"
                    >
                     Remember Me
                    </label>
                  </div>
                  <div className="col-md-5 col-6 form-link text-end">
                    <Link to={`forgotpassword`}>Forgot Password?</Link>
                  </div>
                </div>
                <div className="form-group submit-field">
                  <button className="btn btn-large w-100" id="login">
                   Log In
                  </button>
                </div>

                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                <div>
                  <ReCAPTCHA
                    sitekey="6Lc2JoocAAAAAD3PBcUY0zohtqdFAnK-8t8ii4XW"
                    size=""
                    onChange={confirmCaptcha}
                    verifyCallback={verifyCaptcha}
                    onExpired={expiryCaptcha}
                  />
                  {!confirmcaptcha && (
                    <span>
                      <br />
                      <span className="help-block has_error">
                      Please verify that you are not a robot   </span>
                    </span>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ModelPopup
        show={showTwoFA}
        onHide={() => {
          setShowTwoFA(false);
        }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
            <Fragment>
                <TwoFaLogin
                  pagename={"login"}
                  email={email}
                  password={password}
                  rememberme={rememberMe}
                  closepopup={() => setShowTwoFA(false)}
resendcode={()=>handleLogin(null)}
                />
            </Fragment>
        ]}
      />
    </div>
  );
};

export default Login;
